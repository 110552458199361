import { HYDRATE } from 'next-redux-wrapper';

export const RECEIVE_COMMUNITIES = 'RECEIVE_COMMUNITIES';
export const FETCHING_COMMUNITIES = 'FETCHING_COMMUNITIES';
export const SET_COMMUNITY_FILTER_RESULTS = 'SET_COMMUNITY_FILTER_RESULTS';

const defaultState = {
  communities: {},
  filter: {
    results: [],
    isFetching: false,
    query: {
      page: 0,
    },
  },
};

const communityReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.communities };
    case RECEIVE_COMMUNITIES:
      return {
        ...state,
        communities: action.communities.reduce(
          (result, community) => ({
            ...result,
            [community._id]: community,
          }),
          state.communities,
        ),
      };
    case FETCHING_COMMUNITIES:
      return {
        ...state,
        filter: {
          ...state.filter,
          isFetching: true,
        },
      };
    case SET_COMMUNITY_FILTER_RESULTS:
      return {
        ...state,
        filter: {
          ...state.filter,
          results: action.results,
          query: action.query,
          isFetching: false,
          total: action.total,
        },
      };
    default:
      return state;
  }
};

export default communityReducer;
