import analytics, { gtm } from '../lib/analytics';

export const RECEIVE_CURRENT_PROFILE = 'RECEIVE_CURRENT_PROFILE';
export const FETCHING_FAVORITES = 'FETCHING_FAVORITES';
export const RECEIVE_FAVORITES = 'RECEIVE_FAVORITES';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const REMOVE_FAVORITES = 'REMOVE_FAVORITES';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';

const mergeFavorites = (state, newFavs) => ([
  ...new Set([...state.favorites, ...newFavs]),
]);

const defaultState = {
  isFetching: false,
  favoriteListItems: {},
  favorites: [],
  favoriteInit: false,
  current: {},
};

const userReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_CURRENT_PROFILE: {
      if (
        action.profile.newUser === true
        && Object.getOwnPropertyNames(state.current).length === 0
      ) {
        analytics.track('new_user', {});
      }
      const { profile } = action;
      analytics.identify(profile.userId);

      if (gtm.config?.dataLayer) {
        gtm.config.dataLayer.push({
          event: 'identify',
          user_data: {
            email_address: profile.email,
            phone_number: profile.twilio?.number,
          },
        });
      }

      const { newUser, roles } = state.current;
      return {
        ...state,
        current: {
          newUser,
          roles,
          ...action.profile,
        },
      };
    }
    case FETCHING_FAVORITES: {
      return {
        ...state,
        isFetchingFavorites: true,
      };
    }
    case RECEIVE_FAVORITES: {
      return {
        ...state,
        isFetchingFavorites: false,
        favoriteInit: true,
        favorites: mergeFavorites(
          state,
          action.favorites.filter((f) => f.status !== 'removed').map((f) => f.itemKey),
        ),
        favoriteListItems: action.favorites.reduce(
          (result, f) => ({
            ...result,
            [f._id]: f,
          }),
          state.favoriteListItems,
        ),
      };
    }
    case ADD_FAVORITES: {
      action.favorites.forEach((favoriteId) => analytics.track('add_like', { entityKey: favoriteId }));
      return {
        ...state,
        favorites: mergeFavorites(state, action.favorites),
      };
    }
    case REMOVE_FAVORITES: {
      action.favorites.forEach((favoriteId) => { analytics.track('remove_like', { entityKey: favoriteId }); });
      return {
        ...state,
        favorites: state.favorites.filter((f) => !action.favorites.includes(f)),
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
