import makeThunktankAction from '@tmap/mmm-core/redux/thunktank/makeThunktankAction';

import { getClient } from '../lib/apiClient';

import {
  RECEIVE_CURRENT_PROFILE,
} from '../reducers/user';

export function getCurrentUserProfile() {
  return async (dispatch, getState) => {
    const profile = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).get('/api/v1/user_profiles/current');
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: profile.data,
    });
  };
}

export function updateUserProfile(userUpdate) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SENDING_PROFILE_UPDATE',
    });

    const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post(
      '/api/v1/user_profiles/upsert',
      userUpdate,
      { params: { deepMerge: true, unsetNull: true } },
    );
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: result.data,
    });
  };
}

export function uploadAvatar(file) {
  return async (dispatch, getState) => {
    // dispatch({type:LOGO_UPLOADING,_id,logoType});

    const formData = new FormData();

    formData.append('Content-Type', file.type);
    // Actual file has to be appended last.
    formData.append('file', file);
    const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/user_profiles/avatar', formData);
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: result.data,
    });
  };
}

export const getUserProfileById = makeThunktankAction(
  async (userId) => {
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
      '/api/v1/user_profiles/find',
      {
        filters: {
          userId,
        },
        limit: 1,
      },
    );
    return res.data?.[0];
  },
);

export const getUserProfileByEmailAutocomplete = makeThunktankAction(
  async (emailSearchTerm) => {
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
      '/api/v1/user_profiles/find',
      {
        filters: {
          email: {
            $regex: emailSearchTerm,
          },
        },
        limit: 10,
      },
    );
    const autocomplete = res.data;
    return { autocomplete };
  },
);

export const getUsersbyEmails = makeThunktankAction(
  async (emails) => {
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
      '/api/v1/user_profiles/getIdsByEmail',
      {
        emails,
      },
    );
    return res.data;
  },
);

export const getArgyleAuthToken = makeThunktankAction(
  async () => {
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get('/api/v1/argyle/authtoken');
    return res.data;
  },
);
