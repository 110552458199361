export const EDITOR_LOADING = 'EDITOR_LOADING';
export const EDITOR_INIT = 'EDITOR_INIT';
export const EDITOR_SET_COMMUNITY = 'EDITOR_SET_COMMUNITY';
export const EDITOR_SET_CONTENT = 'EDITOR_SET_CONTENT';
export const EDITOR_CLOSE = 'EDITOR_CLOSE';

export const EDITOR_SELECT_BLOCK = 'EDITOR_SELECT_BLOCK';
export const EDITOR_ADD_BLOCK = 'EDITOR_ADD_BLOCK';
export const EDITOR_DELETE_BLOCK = 'EDITOR_DELETE_BLOCK';
export const EDITOR_DUPLICATE_BLOCK = 'EDITOR_DUPLICATE_BLOCK';
export const EDITOR_HIDE_BLOCK = 'EDITOR_HIDE_BLOCK';
export const EDITOR_UNHIDE_BLOCK = 'EDITOR_UNHIDE_BLOCK';
export const EDITOR_MOVE_BLOCK = 'EDITOR_MOVE_BLOCK';

export const EDITOR_SET_BLOCK_FIELD = 'EDITOR_SET_BLOCK_FIELD';
export const EDITOR_SET_BLOCK_CONTENT = 'EDITOR_SET_BLOCK_CONTENT';
export const EDITOR_MOVE_BLOCK_CONTENT = 'EDITOR_MOVE_BLOCK_CONTENT';

const defaultState = {
  editor: {
    init: false,
    closing: false,
    loading: false,
    saving: false,
    publishing: false,
    modified: false,
    selectedBlock: 0,
  },
  content: {
    blocks: [
      // {
      // type: '',
      // variant: '',
      // data: {
      //   content: [],
      //   title: '',
      //   hidden: false,
      //   ...etc
      // },
      // }
    ],
    isPublished: false,
  },
  community: {},
  dnd: {
    ids: [], // parallel array of dnd ids for content.blocks
    next: 0, // next dnd id to generate, goes up on add/duplicate
  },
};

const generateDndId = (index) => `dnd-block-${index}`;

const communityEditorReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case EDITOR_LOADING: {
      const { save, publish } = action;
      const { editor } = state;
      return {
        ...state,
        editor: {
          ...editor,
          loading: true,
          saving: save,
          publishing: publish,
        },
      };
    }
    case EDITOR_INIT: {
      const { editor } = state;
      return {
        ...state,
        editor: {
          ...editor,
          init: true,
          closing: false,
          loading: false,
        },
      };
    }
    case EDITOR_SET_COMMUNITY: {
      const { community } = action;
      return {
        ...state,
        community,
      };
    }
    case EDITOR_SET_CONTENT: {
      const { content } = action;
      const { editor } = state;
      return {
        ...state,
        editor: {
          ...editor,
          loading: false,
          saving: false,
          publishing: false,
          selectedBlock: editor.selectedBlock < content.blocks.length ? editor.selectedBlock : 0,
          modified: false,
        },
        content,
        dnd: {
          ids: content.blocks.map((b, i) => generateDndId(i)),
          next: content.blocks.length,
        },
      };
    }
    case EDITOR_CLOSE: {
      const { editor } = state;
      return {
        ...state,
        editor: {
          ...editor,
          init: false,
          loading: true,
          closing: true,
        },
      };
    }
    case EDITOR_SELECT_BLOCK: {
      const { blockIndex = 0 } = action;
      const { editor } = state;
      return {
        ...state,
        editor: {
          ...editor,
          selectedBlock: blockIndex,
        },
      };
    }
    case EDITOR_ADD_BLOCK: {
      const { block = {}, blockIndex = 0 } = action;
      const { content, editor, dnd } = state;
      const { blocks } = content;
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 0, block);
      const newDndIds = [...dnd.ids];
      newDndIds.splice(blockIndex, 0, generateDndId(dnd.next));
      return {
        ...state,
        editor: {
          ...editor,
          modified: true,
          selectedBlock: blockIndex,
        },
        content: {
          ...content,
          blocks: newBlocks,
        },
        dnd: {
          ids: newDndIds,
          next: dnd.next + 1,
        },
      };
    }
    case EDITOR_DELETE_BLOCK: {
      const { blockIndex } = action;
      const { content, editor, dnd } = state;
      const { blocks } = content;
      const selectedBlockId = dnd.ids[editor.selectedBlock];
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1);
      const newDndIds = [...dnd.ids];
      newDndIds.splice(blockIndex, 1);
      const selectedBlockAdjusted = newDndIds.indexOf(selectedBlockId);
      return {
        ...state,
        editor: {
          ...editor,
          modified: true,
          selectedBlock: selectedBlockAdjusted !== -1 ? selectedBlockAdjusted : 0,
        },
        content: {
          ...content,
          blocks: newBlocks,
        },
        dnd: {
          ids: newDndIds,
          next: dnd.next,
        },
      };
    }
    case EDITOR_DUPLICATE_BLOCK: {
      const { blockIndex } = action;
      const { content, editor, dnd } = state;
      const { blocks } = content;
      const clone = { ...blocks[blockIndex] };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 0, clone);
      const newDndIds = [...dnd.ids];
      newDndIds.splice(blockIndex, 0, generateDndId(dnd.next));
      return {
        ...state,
        editor: {
          ...editor,
          modified: true,
          selectedBlock: blockIndex + 1,
        },
        content: {
          ...content,
          blocks: newBlocks,
        },
        dnd: {
          ids: newDndIds,
          next: dnd.next + 1,
        },
      };
    }
    case EDITOR_HIDE_BLOCK: {
      const { blockIndex } = action;
      const { content, editor } = state;
      const { blocks } = content;
      const newData = { ...blocks[blockIndex].data, hidden: true };
      const newBlock = { ...blocks[blockIndex], data: newData };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1, newBlock);
      return {
        ...state,
        editor: { ...editor, modified: true },
        content: {
          ...content,
          blocks: newBlocks,
        },
      };
    }
    case EDITOR_UNHIDE_BLOCK: {
      const { blockIndex } = action;
      const { content, editor } = state;
      const { blocks } = content;
      const newData = { ...blocks[blockIndex].data, hidden: false };
      const newBlock = { ...blocks[blockIndex], data: newData };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1, newBlock);
      return {
        ...state,
        editor: { ...editor, modified: true },
        content: {
          ...content,
          blocks: newBlocks,
        },
      };
    }
    case EDITOR_MOVE_BLOCK: {
      const { startIndex, endIndex } = action;
      if (startIndex === endIndex) return state;
      const { content, editor, dnd } = state;
      const { blocks } = content;
      const selectedBlockId = dnd.ids[editor.selectedBlock];
      const newBlocks = [...blocks];
      const [target] = newBlocks.splice(startIndex, 1);
      newBlocks.splice(endIndex, 0, { ...target });
      const newDndIds = [...dnd.ids];
      const [targetId] = newDndIds.splice(startIndex, 1);
      newDndIds.splice(endIndex, 0, targetId);
      const selectedBlockAdjusted = newDndIds.indexOf(selectedBlockId);
      return {
        ...state,
        editor: {
          ...editor,
          modified: true,
          selectedBlock: selectedBlockAdjusted,
        },
        content: {
          ...content,
          blocks: newBlocks,
        },
        dnd: {
          ids: newDndIds,
          next: dnd.next,
        },
      };
    }
    case EDITOR_SET_BLOCK_FIELD: {
      const { blockIndex, field, value } = action;
      const { content, editor } = state;
      const { blocks } = content;
      const newData = { ...blocks[blockIndex].data, [field]: value };
      const newBlock = { ...blocks[blockIndex], data: newData };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1, newBlock);
      return {
        ...state,
        editor: { ...editor, modified: true },
        content: {
          ...content,
          blocks: newBlocks,
        },
      };
    }
    case EDITOR_SET_BLOCK_CONTENT: {
      const { blockIndex, contentIndex, value } = action;
      const { content, editor } = state;
      const { blocks } = content;
      const newBlockContent = [...(blocks[blockIndex].data.content || [])];
      if (value) {
        newBlockContent.splice(contentIndex, 1, value);
      } else {
        newBlockContent.splice(contentIndex, 1);
      }
      const newData = { ...blocks[blockIndex].data, content: newBlockContent };
      const newBlock = { ...blocks[blockIndex], data: newData };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1, newBlock);
      return {
        ...state,
        editor: { ...editor, modified: true },
        content: {
          ...content,
          blocks: newBlocks,
        },
      };
    }
    case EDITOR_MOVE_BLOCK_CONTENT: {
      const { blockIndex, startIndex, endIndex } = action;
      if (startIndex === endIndex) return state;
      const { content, editor } = state;
      const { blocks } = content;
      const newBlockContent = [...blocks[blockIndex].data.content];
      const [target] = newBlockContent.splice(startIndex, 1);
      newBlockContent.splice(endIndex, 0, { ...target });
      const newData = { ...blocks[blockIndex].data, content: newBlockContent };
      const newBlock = { ...blocks[blockIndex], data: newData };
      const newBlocks = [...blocks];
      newBlocks.splice(blockIndex, 1, newBlock);
      return {
        ...state,
        editor: { ...editor, modified: true },
        content: {
          ...content,
          blocks: newBlocks,
        },
      };
    }
    default:
      return state;
  }
};

export default communityEditorReducer;
