import { createMmmTheme, DARK_MODE, LIGHT_MODE } from '@tmap/mmm-style-guide/src/Theme';

export const LIGHT_THEME = createMmmTheme({ mode: LIGHT_MODE });
export const DARK_THEME = createMmmTheme({ mode: DARK_MODE });

// themes for force breakpoint compatibility
export const EXTEND_LIGHT_THEME = (parentTheme) => createMmmTheme({
  mode: LIGHT_MODE,
  forceBreakpoint: parentTheme.forceBreakpoint,
});
export const EXTEND_DARK_THEME = (parentTheme) => createMmmTheme({
  mode: DARK_MODE,
  forceBreakpoint: parentTheme.forceBreakpoint,
});
