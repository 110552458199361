import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from './gaPlugin';

export const gtm = googleTagManager({
  containerId: process.env.NEXT_PUBLIC_GTM,
  enabled: false,
});
// GTM is disabled until manually activated to avoid page load overhead
const analytics = Analytics({
  app: 'www',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.NEXT_PUBLIC_GA],
      debug: process.env.NEXT_PUBLIC_GA_DEBUG_MODE === 'true',
    }),
    gtm,
  ],
});

/** Track an outbound URL click in GA4. Internal URLs will not accidenally be tracked */
export const trackOutbound = (url) => new Promise((resolve) => {
  const domain = url.match(/^(?:https?:\/\/)?([^/\n]+)(?:\/(?:.+?)?)?$/i)?.[1];
  // No domain? Do not track, relative URL
  if (domain && !(domain === 'makemymove.com' || domain.endsWith('.makemymove.com'))) {
    analytics.track(
      'click',
      {
        outbound: true,
        link_url: url,
        link_domain: domain,
        event_callback: resolve,
        event_timeout: 3000,
      },
    );
  } else {
    resolve();
  }
});

export default analytics;
