import { HYDRATE } from 'next-redux-wrapper';

export const RECEIVE_INCENTIVES = 'RECEIVE_INCENTIVES';
export const FETCHING_INCENTIVES = 'FETCHING_INCENTIVES';
export const SET_INCENTIVE_FILTER_RESULTS = 'SET_INCENTIVE_FILTER_RESULTS';

const defaultState = {
  incentives: {},
  filter: {
    results: [],
    isFetching: false,
    query: {
      page: 0,
    },
  },
};

const incentiveReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.incentives };
    case RECEIVE_INCENTIVES:
      return {
        ...state,
        incentives: action.incentives.reduce(
          (result, incentive) => ({
            ...result,
            [incentive._id]: incentive,
          }),
          state.incentives,
        ),
      };
    case FETCHING_INCENTIVES:
      return {
        ...state,
        filter: {
          ...state.filter,
          isFetching: true,
        },
      };
    case SET_INCENTIVE_FILTER_RESULTS:
      return {
        ...state,
        filter: {
          ...state.filter,
          results: action.results,
          query: action.query,
          isFetching: false,
          total: action.total,
        },
      };
    default:
      return state;
  }
};

export default incentiveReducer;
