import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { getUserFavorites } from '../actions/user';
import { receiveLogin } from '../actions/auth';
import { getCurrentUserProfile, updateUserProfile } from '../actions/userProfile';

export default function UserFavoritesProvider(props) {
  const user = useAuth0();
  const authStore = useSelector((state) => state?.authStore || {}, shallowEqual);
  const favoriteInit = useSelector((state) => state?.users?.favoriteInit, shallowEqual);
  const profile = useSelector((state) => state?.users?.current || {}, shallowEqual);
  const isFetchingFavorites = useSelector((state) => (
    state?.users?.isFetchingFavorites
  ), shallowEqual);

  const dispatch = useDispatch();

  // fires on page refresh / login event
  useEffect(() => {
    // Check email to make sure their account has been initialized by getCurrentUserProfile
    if (authStore.id_token && profile.email) {
      dispatch(updateUserProfile({
        recentActivity: {
          visitedSite: (new Date()).toISOString(),
        },
      }));
    }
  }, [dispatch, authStore.id_token, profile.email]);

  useEffect(() => {
    (async () => {
      if (user.isAuthenticated) {
        const claims = await user.getIdTokenClaims();
        const { __raw: raw } = claims;
        dispatch(receiveLogin({ id_token: raw }));
      }

      if (authStore.id_token && !favoriteInit && !isFetchingFavorites) {
        dispatch(getUserFavorites());
        if (Object.keys(profile).length === 0) {
          dispatch(getCurrentUserProfile());
        }
      }
    })();
  }, [
    dispatch,
    user.isAuthenticated,
    authStore.id_token,
    user,
    favoriteInit,
    isFetchingFavorites,
    props,
    profile,
  ]);

  return props.children;
}
