// src/store.js

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createWrapper } from 'next-redux-wrapper';
import rootReducer from './reducers';

const middleware = composeWithDevTools(applyMiddleware(promise, thunk));

export const store = createStore(rootReducer, middleware);

export const wrapper = createWrapper(() => store, { debug: true });
