export const ACTIVATE_TUTORIAL = 'ACTIVATE_TUTORIAL';
export const UPDATE_TUTORIAL = 'UPDATE_TUTORIAL';
export const COMPLETE_TUTORIAL = 'COMPLETE_TUTORIAL';
export const CANCEL_TUTORIAL = 'CANCEL_TUTORIAL';
export const LOAD_COMPLETED_TUTORIALS = 'LOAD_COMPLETED_TUTORIALS';
export const DISABLE_TUTORIAL = 'DISABLE_TUTORIAL';

const defaultState = {
  activeTutorial: null,
  activeTutorialData: {},
  completedTutorials: {},
  disabledTutorials: {},
};

const tutorialsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ACTIVATE_TUTORIAL:
      // safeguard, refuse to activate tutorial that is disabled
      if (state.disabledTutorials[action.payload.tutorial]) return state;
      return {
        ...state,
        activeTutorial: action.payload.tutorial,
        activeTutorialData: { ...action.payload.tutorialData, tutorial: undefined },
      };
    case UPDATE_TUTORIAL:
      // safeguard, refuse to update tutorial that isn't running
      if (action.payload.tutorial !== state.activeTutorial) return state;
      return {
        ...state,
        activeTutorialData: { ...action.payload.tutorialData, tutorial: undefined },
      };
    case COMPLETE_TUTORIAL:
      // safeguard, refuse to complete tutorial that isn't running
      if (action.payload.tutorial !== state.activeTutorial) return state;
      return {
        ...state,
        activeTutorial: null,
        activeTutorialData: {},
        completedTutorials: {
          [action.payload.tutorial]: new Date(),
        },
      };
    case CANCEL_TUTORIAL:
      // safeguard, refuse to cancel tutorial that isn't running
      if (action.payload.tutorial !== state.activeTutorial) return state;
      return {
        ...state,
        activeTutorial: null,
        activeTutorialData: {},
      };
    case LOAD_COMPLETED_TUTORIALS:
      return {
        ...state,
        completedTutorials: {
          ...state.completedTutorials,
          ...action.payload.completedTutorials,
        },
      };
    case DISABLE_TUTORIAL:
      return {
        ...state,
        // cancel the payload.tutorial if it is running
        ...(state.activeTutorial === action.payload.tutorial ? {
          activeTutorial: null,
          activeTutorialData: {},
        } : {}),
        disabledTutorials: {
          ...state.disabledTutorials,
          [action.payload.tutorial]: true,
        },
      };
    default:
      return state;
  }
};

export default tutorialsReducer;
