import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

const PageLoadedContext = createContext(false);

export default function PageLoadedProvider({ children }) {
  const [pageLoaded, setPageLoaded] = useState(false);

  const onPageLoad = () => setPageLoaded(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <PageLoadedContext.Provider value={pageLoaded}>
      {children}
    </PageLoadedContext.Provider>
  );
}

export function usePageLoaded() {
  return useContext(PageLoadedContext);
}
