import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { useInView } from 'react-intersection-observer';
import analytics from '../lib/analytics';
// const analytics = { track: console.log }; // for testing convenience

class ImpressionHistory {
  constructor() {
    this.history = {};
  }

  shouldTrack(impressionId) {
    const isUnique = !this.history[impressionId];
    if (isUnique) this.history[impressionId] = true;
    return isUnique;
  }

  reset() {
    this.history = {};
  }
}

export const impressionHistory = new ImpressionHistory();

const PassthroughDiv = styled('div')(() => ({
  display: 'inline',
}));

export const ImpressionTracker = forwardRef((props, elementRef) => {
  const {
    trackView,
    // creates the event handler for you, for convenience
    trackClick = undefined,
    // overrides trackClick if you need access to event arguments
    onClickCapture = undefined,
    threshold = 0.75,
    children,
    ...otherProps
  } = props;
  const { inView, ref: viewRef } = useInView({ threshold });

  useEffect(() => {
    if (inView) {
      trackView();
    }
  }, [inView, trackView]);

  // React has synthetic event handling in this order
  // - react capture phase
  // - native capture phase
  // - native bubble phase
  // - react bubble phase
  // In order to support native libraries (swiper) canceling tracking events
  // in the capture phase, we'll create our click handler as a native capture event.
  // NOTE: This will cause any react onClickCapture handlers that stop
  // event propagation to not track an impression, even if they are child of
  // the TrackingWrapper component. This should generally be fine, since
  // click tracking should be targeting click events in the bubble phase.

  const eventRef = useRef();
  const mergedRef = (node) => {
    [viewRef, elementRef].forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (typeof ref === 'object' && ref !== null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = node;
      }
    });
    eventRef.current = node;
  };

  useEffect(() => {
    const node = eventRef?.current;
    const handleClick = (e) => {
      if (onClickCapture) onClickCapture(e);
      else if (trackClick) trackClick();
    };
    if (node) node.addEventListener('click', handleClick, true);
    return () => {
      if (node) node.removeEventListener('click', handleClick, true);
    };
  }, [eventRef, onClickCapture, trackClick]);

  return (
    <PassthroughDiv role='none' {...otherProps} ref={mergedRef}>
      {children}
    </PassthroughDiv>
  );
});

const isObjectEvent = (object) => object instanceof Event || object?.nativeEvent instanceof Event;

/**
 * Render-optimized hook for generating impression tracking functions
 * @param {String} entityKey - identifier in entity key format for tracked document
 * @param {String} placementId - unique identifier for page placement of component
 * @param {Object} [additionalMetadata]
 * - additional tracking metadata provided at hook call
 * - (!) please generate using a useMemo with exhaustive deps for render optimization
 * @returns {Object} containing tracking functions that
 * - can be called manually for granular tracking
 * - can be passed to an ImpressionTracker
 */
const useImpressions = (entityKey, placementId, additionalMetadata) => {
  /**
   * Function to track an impressions click
   * @param {Object} [eventMetadata] - optional granular event metadata
   */
  const trackClick = useCallback((eventMetadata) => {
    if (isObjectEvent(eventMetadata)) {
      throw Error('useImpressions usage error: do not use trackClick as an event handler, this will pollute GA with excess event metadata');
    }
    if (!entityKey || !placementId) return; // do not track when not enough info
    analytics.track(
      'impression_click',
      {
        entityKey,
        placementId,
        ...additionalMetadata,
        ...eventMetadata,
      },
    );
  }, [entityKey, placementId, additionalMetadata]);

  /**
   * Function to track an impressions view. Ensures uniqueness by entityKey and placementId.
   * @param {Object} [eventMetadata] - optional granular event metadata
   */
  const trackView = useCallback((eventMetadata) => {
    if (isObjectEvent(eventMetadata)) {
      throw Error('useImpressions usage error: do not use trackView as an event handler, this will pollute GA with excess event metadata');
    }
    if (!entityKey || !placementId) return; // do not track when not enough info
    const impressionId = [entityKey, placementId].join('@');
    if (impressionHistory.shouldTrack(impressionId)) {
      analytics.track(
        'impression_view',
        {
          entityKey,
          placementId,
          ...additionalMetadata,
          ...eventMetadata,
        },
      );
    }
  }, [entityKey, placementId, additionalMetadata]);

  return useMemo(() => ({ trackView, trackClick }), [trackView, trackClick]);
};

export default useImpressions;
