export const FETCHING_COMMON_APP_CONFIG = 'FETCHING_COMMON_APP_CONFIG';
export const RECEIVE_COMMON_APP_CONFIG = 'RECEIVE_COMMON_APP_CONFIG';

const defaultState = {
  config: {
    isFetching: null,
  },
};

const commonAppReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCHING_COMMON_APP_CONFIG:
      return {
        ...state,
        config: {
          isFetching: true,
        },
      };
    case RECEIVE_COMMON_APP_CONFIG:
      return {
        ...state,
        config: {
          app: action.config.app,
          data: action.config.fields,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export default commonAppReducer;
